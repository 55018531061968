import {LOGIN_USER, LOGOUT_USER, FETCH_USER, LOGIN_IF_AUTHENTICATED, FETCH_CONFIG} from '../actionTypes';
import {SET_AUTH_TOKEN, SET_CONFIG, SET_USER} from '../mutationTypes';
import store from "./index";
import alert_actions from "./actions/alert"
import error_handler from "./actions/error_handler"

export default {
    ...alert_actions,
    ...error_handler,
    [LOGIN_USER]({commit}, data) {
        return new Promise((resolve, reject) => {
            this._vm.$axios.post('/auth/token', data)
              .then(response => {
                  commit(SET_AUTH_TOKEN, response.data.data)
                  resolve(true)
              })
              .catch(err => {
                  reject(err);
              });
        });
    },
    [LOGOUT_USER]({commit}, data) {
        return new Promise((resolve, reject) => {
            this._vm.$axios.post('/auth/logout', data)
              .then(response => {
                  this._vm.$cookies.remove('access_token')
                  commit(SET_AUTH_TOKEN, null)
                  commit(SET_USER, []);
                  resolve(response)
              })
              .catch(err => {
                  reject(err);
              });
        });
    },
    [FETCH_USER]({commit}, ignore_errors) {
        return new Promise((resolve, reject) => {
            this._vm.$axios.get('/auth/me')
              .then(response => {
                  commit(SET_USER, response.data.data);
                  resolve(true);
              })
              .catch(err => {
                  reject(err);
              });
        });
    },
    [LOGIN_IF_AUTHENTICATED]() {
        return new Promise((resolve, reject) => {
            const url = new URL(window.location.href)
            const url_bearer = url.searchParams.get('bearer')
            if (url_bearer) {
                this._vm.$cookies.set('access_token', url_bearer, '7d')
                url.searchParams.delete('bearer');
                window.history.pushState({}, '', url);
            }

            const bearer = this._vm.$cookies.get('access_token');
            if (bearer) {
                window.localStorage.setItem(store.state.local_storage_access_token_key, bearer);
                window.localStorage.setItem(store.state.local_storage_token_type_key, 'Bearer');
            }
            let token = window.localStorage.getItem(store.state.local_storage_access_token_key);
            let type = window.localStorage.getItem(store.state.local_storage_token_type_key);
            if (token && type) {
                store.dispatch(FETCH_USER, true).finally(() => resolve())
            } else {
                resolve();
            }
        });
    },
    [FETCH_CONFIG]({ commit, state }, payload) {
        const query = payload && payload.params && payload.params.length ? '?' + payload.params : '';
        return new Promise((resolve, reject) => {
            this._vm.$axios.get('/dashboard/config' + query).then(response => {
                let newConfig;
                if(payload && payload.params) {
                    let config = state.config;
                    Object.keys(response.data.data).forEach((key) => {
                        config[key] = response.data.data[key];
                    })
                    newConfig = config;
                } else {
                    newConfig = response.data.data;
                }
                commit(SET_CONFIG, newConfig);
                resolve(response);
            }).catch(err => {
                reject(err);
            });
        });
    },
};
