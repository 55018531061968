import Vue from 'vue'
import Router from 'vue-router'

// Routes
import faqs_routes from './routes/faqs'
import api_keys_routes from './routes/api_keys'

// Containers
const Container = () => import('../containers/Container')

// Views
const Dashboard = () => import('../components/menu/Dashboard/Dashboard')

// Pages
const Error403 = () => import('../views/pages/Page403')
const Error404 = () => import('../views/pages/Page404')
const Login = () => import('../views/pages/Login.vue')

Vue.use(Router)

const route_error_403 = {
    path: '/error/403',
    name: 'error.403',
    component: Error403,
}

const router = new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'open active',
    scrollBehavior: () => ({y: 0}),
    routes: [
        {
            path: '/',
            component: Container,
            meta: {
                'auth': true,
            },
            children: [
                {
                    path: '/',
                    name: 'dashboard',
                    component: Dashboard,
                },
                api_keys_routes,
                faqs_routes,
            ],
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                'guest': true,
            },
        },
        {
            path: '/*',
            name: 'error_404',
            component: Error404,
        },
    ],
})

router.beforeEach((to, from, next) => {
    // Unauthenticated user redirect to login page
    if (to.matched.some(m => m.meta.auth === true) && !router.app.$user.check()) {
        return router.push({name: 'login'})
    }

    // Authenticated user redirect to tenants
    if (to.matched.some(m => m.meta.guest === true) && router.app.$user.check()) {
        return router.push({name: 'dashboard'})
    }

    // Get all permissions of the route and all its parents
    let permissions = []
    to.matched.forEach((route) => {
        if (route.meta.permissions) {
            permissions = permissions.concat(Array.isArray(route.meta.permissions) ? route.meta.permissions : [route.meta.permissions])
        }
    })

    // Check permissions and redirect to 403 if authenticated user doesn't have permissions to view the page
    if (!router.app.$user.canAll(permissions)) {
        router.addRoutes([route_error_403])
        return router.replace({name: 'error.403'})
    }

    return next()
})

export default router
